//fonts
$primary: #bf506c;
$secondary: #ddd;

//backgrounds
$bg-primary: black;
$bg-primary-light: #222;
$bg-secondary: #bf506c;
$bg-dock: #222;

$transition: 0.3s ease all;

$sm: 768px;
