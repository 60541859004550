@import "vars";

@font-face {
  font-family: "Averta";
  font-weight: 600;
  src: url("https://firebasestorage.googleapis.com/v0/b/harry-74f87.appspot.com/o/Averta-Semibold.woff?alt=media&token=6be3cbf6-a299-4287-88c6-0baef80eaf05");
}

html,
body,
#root,
.content {
  height: 100%;
  min-height: 700px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Muli", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bg-primary;
  color: $primary;
  min-width: 340px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary;
}

a {
  color: $primary;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    color: $primary;
    text-decoration: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  text-align: center;
}

section {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#landing {
  user-select: none;
  height: 100vh;
  min-height: 600px;
  position: relative;

  $window-length: 180px;
  $border-width: 8px;
  #window {
    height: $window-length;
    width: $window-length;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/harry-74f87.appspot.com/o/FA9B341B-E756-405C-9A73-AD06EA7A4A53.jpeg?alt=media&token=0d8da4be-8c17-433b-85a4-4e91223a86a8");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    border: $border-width solid $bg-primary;
    box-sizing: content-box;
  }
  #window-container {
    height: $window-length + 4 * $border-width;
    width: $window-length + 4 * $border-width;
    border-radius: 50%;
    transition: $transition;
    margin-bottom: 15px;
    &:hover {
      transform: scale(0.99);
    }
  }
  #window-inner-container {
    height: $window-length + 2 * $border-width;
    width: $window-length + 2 * $border-width;
    border-radius: 50%;
    border: $border-width solid $primary;
    box-sizing: content-box;
  }
  h1 {
    font-size: 2rem;
  }

  .links-container {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    margin: 0px;
    transition: $transition;
    padding-bottom: 10px;

    a {
      display: block;
      width: 50px;
    }
    p.link-desc {
      font-size: 0.7rem;
      margin: 0px;
    }
  }
  .desc {
    font-size: 1rem;
  }
  .interest-icon-span {
    font-size: 1rem;
  }
  .interest-icon {
    margin-right: 6px;
  }
  .active-interest {
    display: none;
  }
  @media (min-width: $sm) {
    $window-length: 250px;
    $border-width: 10px;
    #window {
      height: $window-length;
      width: $window-length;
      background-image: url("https://firebasestorage.googleapis.com/v0/b/harry-74f87.appspot.com/o/FA9B341B-E756-405C-9A73-AD06EA7A4A53.jpeg?alt=media&token=0d8da4be-8c17-433b-85a4-4e91223a86a8");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      border: $border-width solid $bg-primary;
    }
    #window-container {
      height: $window-length + 4 * $border-width;
      width: $window-length + 4 * $border-width;
      border-radius: 50%;
      transition: $transition;
      &:hover {
        transform: scale(0.99);
      }
    }
    #window-inner-container {
      height: $window-length + 2 * $border-width;
      width: $window-length + 2 * $border-width;
      border-radius: 50%;
      border: $border-width solid $primary;
    }
    h1 {
      font-size: 5rem;
      margin-bottom: 30px;
    }

    .links-container {
      position: fixed;
      z-index: 100;
      width: 100%;
      left: 0px;
      bottom: 0px;
      transition: $transition;
      padding-bottom: 10px;

      .links-dock {
        background: $bg-dock;
        padding: 20px 30px 0px;
        border-radius: 1rem;
        animation-delay: 0.3s;
      }
      p.link-desc {
        font-size: 0.8rem;
        visibility: hidden;
        margin: 0px;
      }
      .link {
        display: block;
        width: 50px;
        transition: $transition;
      }
      .link:hover {
        transition: $transition;
        transform: translateY(-5px);
        p.link-desc {
          visibility: visible;
        }
      }
    }
    .desc {
      font-size: 1.1rem;
    }
    .interest-icon-span {
      font-size: 1.5rem;
    }
    .interest-icon {
      margin-right: 6px;
    }
    .active-interest {
      display: block;
      font-size: 3rem;
      font-weight: 400;
    }
  }
}

section#projects {
  padding-left: 20px;
  padding-right: 20px;
}

.project-container {
  height: 300px;
  width: 300px;
  display: inline-block;
  padding: 10px;
}

.project-card {
  border-radius: 5px;
  height: 100%;
  transition: $transition;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(0.98);
  }
}
img.project-img {
  width: 80%;
}
.project-title {
  font-size: 1.8rem;
  font-weight: 200;
  margin-bottom: 0px;
}

.modal-content {
  background: $bg-primary-light;
  border: none;
  color: $secondary;
  white-space: pre-wrap;

  .modal-bg {
    margin: -1rem -1rem 1rem;
    height: 200px;
    background-color: $bg-primary;
    border-radius: 0.3rem 0.3rem 0 0;
  }
  .modal-logo {
    width: 40%;
  }
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
  }
  a {
    width: 100%;
    text-align: center;
    color: $secondary;
  }
}

@media (min-width: $sm) {
  section {
    padding: 100px;
  }
  .modal-content {
    .modal-bg {
      height: 400px;
    }
    .modal-logo {
      width: 70%;
    }
  }
}

@media (prefers-color-scheme: dark) {
  @import "vars_dark";
  body {
    background: $bg-primary;
    color: $primary;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary;
  }

  a {
    color: $primary;
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
  #landing {
    #window {
      border-color: $bg-primary;
    }
    #window-inner-container {
      border-color: $primary;
    }
    @media (min-width: $sm) {
      .links-container {
        .links-dock {
          background: $bg-dock;
        }
      }
      #window {
        border-color: $bg-primary;
      }
      #window-inner-container {
        border-color: $primary;
      }
    }
  }

  .modal-content {
    background: $bg-primary-light;
    color: $secondary;
    .modal-bg {
      background-color: $bg-primary;
    }
    a {
      color: $secondary;
    }
  }
}
