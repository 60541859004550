//fonts
$primary: navy;
$secondary: #444;

//backgrounds
$bg-primary: white;
$bg-primary-light: white;
$bg-secondary: navy;
$bg-dock: #f5f5f5;

$transition: 0.3s ease all;

$sm: 768px;
